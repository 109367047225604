import React, { useState, useContext, useEffect, useCallback } from "react";
import Api from "../../api";
import formatDate from "../../utils/formatDate";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import "./index.css";
import Paper from "@material-ui/core/Paper";
import { context as UserContext } from "../../providers/user.provider";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import Timer from "@material-ui/icons/Timer";
import { Button, TextField } from "@material-ui/core";
import { useRole } from "../../utils/useRole";
import { Redirect } from "react-router-dom";

const initialBlockState = {
  seats: null,
  email: null,
  mobileNumber: null,
  schedules: [],
};

let initialFilterState = {
  type: "joyRide",
  travelDate: new Date().toISOString(),
};

try {
  const parsedFilterState = JSON.parse(localStorage.getItem("bookingsFilter"));
  if (parsedFilterState) {
    if (parsedFilterState.travelDate) {
      const now = new Date();
      const travelDate = new Date(parsedFilterState.travelDate);

      if (travelDate < now) {
        parsedFilterState.travelDate = now.toISOString();
      }
    }
    initialFilterState = parsedFilterState;
  }
} catch (err) {
  console.log("Error parsing filter state", err);
}

const BookingsPage = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [schedules, setSchedules] = useState([]);
  const [block, setBlock] = useState(initialBlockState);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [blockSeatsDialogOpen, setBlockSeatsDialogOpen] = useState(false);
  const role = useRole();

  const [state, setState] = useState({
    type: initialFilterState.type,
    travelDate: new Date(initialFilterState.travelDate),
  });

  const context = useContext(UserContext);

  useEffect(() => {
    if (isLoading) {
      context.showLoading();
    } else {
      context.hideLoading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    localStorage.setItem(
      "bookingsFilter",
      JSON.stringify({ type: state.type, travelDate: state.travelDate })
    );
  }, [state]);

  const getBookings = useCallback(() => {
    const { type, travelDate } = state;

    setIsLoading(true);
    Api.getBookings(formatDate(travelDate), type)
      .then((res) => res.json())
      .then(setBookingsInSchedules)
      .finally(() => setIsLoading(false));
  }, [state]);

  const getJoyRideSchedules = useCallback(() => {
    const { type, travelDate } = state;

    if (type === "joyRide") {
      Api.getJoyRideSchedules(formatDate(travelDate))
        .then((res) => res.json())
        .then((res) => {
          const { schedules } = res;
          setBlock((prev) => ({ ...prev, schedules }));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [state]);

  const getJoyRideSchedulesAndBookings = useCallback(() => {
    getJoyRideSchedules();
    getBookings();
  }, [getBookings, getJoyRideSchedules]);

  useEffect(() => {
    getJoyRideSchedulesAndBookings();
  }, [getJoyRideSchedulesAndBookings]);

  const getScheduleName = (schedule) => {
    if (schedule.path.name && schedule.name) {
      return `${schedule.path.name} ${schedule.name}`;
    }
  };

  const onHandleDateChange = (travelDate) => {
    setState({
      type: state.type,
      travelDate,
    });
  };

  const onHandleTypeChange = ({ target: { value: type } }) => {
    setState({ type, travelDate: state.travelDate });
  };

  const openDetailsDialog = (selectedBooking) => {
    setSelectedBooking(selectedBooking);
    setDetailsDialogOpen(true);
  };

  const closeBlockSeatsDialog = () => {
    setBlockSeatsDialogOpen(false);
    setSelectedSchedule(null);
  };

  const openBlockSeatsDialog = () => {
    setBlockSeatsDialogOpen(true);
  };

  const closeDetailsDialog = () => {
    setSelectedBooking(null);
    setDetailsDialogOpen(false);
  };

  const handleBlockFormChanged = (field, value) => {
    const { block } = state;

    if (field === "selectedSchedule") {
      const selectedSchedule = block.schedules.find((s) => s._id === value);

      setSelectedSchedule(selectedSchedule);
    } else {
      setBlock({
        ...block,
        [field]: value,
      });
    }
  };

  const setBookingsInSchedules = (bookings = []) => {
    if (!Array.isArray(bookings)) {
      return;
    }

    const schedules = bookings.reduce((acc, curr) => {
      const { schedule } = curr;
      const scheduleIndex = acc.findIndex((s) => s._id === schedule._id);

      if (scheduleIndex !== -1) {
        acc[scheduleIndex].bookings.push(curr);
      } else {
        acc.push({
          ...schedule,
          bookings: [curr],
        });
      }

      return acc;
    }, []);

    setSchedules(schedules);
  };

  const onBlockSeatsSubmit = async () => {
    const { travelDate, selectedSchedule } = state;
    const { email, mobileNumber, seats: seatCount } = block;

    if (!email || !mobileNumber || !seatCount || !selectedSchedule) {
      return false;
    }

    const {
      _id: schedule,
      path: {
        halts: [{ _id: fromHalt }, { _id: toHalt }],
      },
    } = selectedSchedule;

    setIsLoading(true);
    try {
      const response = await Api.blockSeats({
        schedule,
        mobileNumber,
        email,
        seatCount,
        travelDate: formatDate(travelDate),
        fromHalt,
        toHalt,
      });
      const data = await response.json();
      setIsLoading(false);
      if (data.status === "SUCCESS") {
        context.showSuccess(data.message);
        getBookings();
        closeBlockSeatsDialog();
      } else {
        context.showError(data.message);
        closeBlockSeatsDialog();
      }
    } catch (err) {
      context.showError("Something went wrong!");
    }
  };

  const { travelDate, type } = state;

  if (role !== "admin") {
    return <Redirect to="/charters" />;
  }

  return (
    <div>
      <h2>Bookings</h2>
      <Dialog
        onClose={closeDetailsDialog}
        open={detailsDialogOpen}
        maxWidth={false}
      >
        <DialogTitle id="customized-dialog-title" onClose={closeDetailsDialog}>
          Passenger Details
        </DialogTitle>
        <DialogContent>
          {selectedBooking && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Age</TableCell>
                  <TableCell>Weight</TableCell>
                  <TableCell>Govt. Id Number</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedBooking.passengerIds.length > 0
                  ? selectedBooking.passengerIds.map((p, index) => (
                      <TableRow>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{p.name}</TableCell>
                        <TableCell>{p.age}</TableCell>
                        <TableCell>{p.weight}</TableCell>
                        <TableCell>{p.idNumber}</TableCell>
                      </TableRow>
                    ))
                  : selectedBooking.passengers.length > 0
                  ? selectedBooking.passengers.map((p, index) => (
                      <TableRow>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{p}</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                      </TableRow>
                    ))
                  : "No details found. Contact developer with Booking Id"}
              </TableBody>
            </Table>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDetailsDialog}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={blockSeatsDialogOpen}
        style={{ maxWidth: "600px", margin: "auto" }}
        maxWidth="lg"
      >
        <DialogTitle className="dialog-title" style={{ fontSize: "16px" }}>
          Block Seats
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel>Schedule</InputLabel>
            <Select
              name="schedule"
              label="Schedule"
              fullWidth
              onChange={(e) =>
                handleBlockFormChanged("selectedSchedule", e.target.value)
              }
              value={selectedSchedule ? selectedSchedule._id : 0}
            >
              <MenuItem disabled={true} value={0}>
                Select a Schedule
              </MenuItem>
              {block.schedules.map((s) => (
                <MenuItem value={s._id}>{getScheduleName(s)}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            id="outlined-email"
            label="Email"
            value={block.email || ""}
            onChange={(e) => handleBlockFormChanged("email", e.target.value)}
            fullWidth
            margin="normal"
            // variant="outlined"
          />
          <TextField
            id="outlined-name"
            label="Mobile Number"
            onChange={(e) =>
              handleBlockFormChanged("mobileNumber", e.target.value)
            }
            value={block.mobileNumber || ""}
            fullWidth
            margin="normal"
            // variant="outlined"
          />
          <TextField
            id="outlined-name"
            value={block.seats || ""}
            onChange={(e) => handleBlockFormChanged("seats", e.target.value)}
            label="Number of Seats"
            fullWidth
            type="number"
            margin="normal"
            // variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeBlockSeatsDialog}>Close</Button>
          <Button onClick={onBlockSeatsSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
      <div style={{ textAlign: "left" }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            defaultValue={new Date()}
            margin="normal"
            className="HelloWorld"
            label="Travel Date"
            style={{ marginBottom: "0" }}
            value={travelDate}
            onChange={(value) => {
              onHandleDateChange(value);
            }}
          />
          <FormControl
            style={{
              verticalAlign: "bottom",
              width: "200px",
              marginLeft: "10px",
            }}
          >
            <InputLabel>Ride Type</InputLabel>
            <Select onChange={onHandleTypeChange} value={type} name="Type">
              <MenuItem value={"joyRide"}>Joy Ride</MenuItem>
            </Select>
          </FormControl>
        </MuiPickersUtilsProvider>
        {type === "joyRide" && Boolean(block.schedules.length) && (
          <div
            className="button-red-small"
            style={{ float: "right" }}
            onClick={() => openBlockSeatsDialog()}
          >
            Block Seats
          </div>
        )}
      </div>
      {schedules.map((schedule) => {
        const { bookings } = schedule;
        return (
          <div
            key={schedule._id}
            style={{
              background: "white",
              margin: "10px 0",
              border: "1px solid #ccc",
              padding: "10px",
            }}
          >
            <h3 className="align-left">
              {schedule.path.halts.map((halt) => halt.name).join(" - ")}
            </h3>
            <h4 className="align-left">
              {schedule.arrivalTime.map((at) => at).join(" - ")}
            </h4>

            <Paper>
              {schedule.type === "joyRide" ? (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Mobile No</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Payment ID / Booking URL</TableCell>
                      <TableCell>SeatCount</TableCell>
                      <TableCell>Details</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bookings.map((booking) => {
                      return (
                        <TableRow key={booking._id}>
                          <TableCell>
                            <div>
                              {booking._id}
                              {booking.status === "blocked" && (
                                <span
                                  style={{
                                    fontSize: "8px",
                                    display: "inline-block",
                                    padding: "3px",
                                    float: "right",
                                    background: "red",
                                    verticalAlign: "text-bottom",
                                    color: "white",
                                    fontWeight: 800,
                                    borderRadius: "3px",
                                  }}
                                >
                                  BLOCKED
                                </span>
                              )}
                            </div>
                          </TableCell>
                          <TableCell>{booking.mobileNumber}</TableCell>
                          <TableCell>{booking.email}</TableCell>
                          <TableCell>
                            {booking.payment ? (
                              booking.payment._id
                            ) : (
                              <span>
                                <Timer style={{ verticalAlign: "bottom" }} />{" "}
                                {booking.shortUrl
                                  ? booking.shortUrl
                                  : "Pending"}
                              </span>
                            )}
                          </TableCell>
                          <TableCell>{booking.seatCount}</TableCell>
                          <TableCell
                            onClick={() => {
                              openDetailsDialog(booking);
                            }}
                          >
                            <div className="button-small">Show Details</div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Extra</TableCell>
                      <TableCell>Mobile No</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Payment ID</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bookings.map((booking) =>
                      booking.passengers.map((p, index) => (
                        <TableRow key={`${booking._id}-${index}`}>
                          <TableCell>{booking._id}</TableCell>
                          <TableCell>{p.name ? p.name : p}</TableCell>
                          <TableCell>
                            {booking.airportTransfer ? (
                              <Chip
                                className="chip"
                                label={`Airport Transfer : ${booking.airlineNumber}`}
                              />
                            ) : (
                              ""
                            )}
                            {booking.wheelChair ? (
                              <Chip className="chip" label={`Wheel Chair`} />
                            ) : (
                              ""
                            )}
                            {booking.surfaceTransport ? (
                              <Chip
                                className="chip"
                                label={`Surface Transport`}
                              />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell>{booking.mobileNumber}</TableCell>
                          <TableCell>{booking.email}</TableCell>
                          <TableCell>
                            {booking.payment ? (
                              booking.payment._id
                            ) : (
                              <span>
                                <Timer style={{ verticalAlign: "bottom" }} />{" "}
                                Pending
                              </span>
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              )}
            </Paper>
          </div>
        );
      })}
    </div>
  );
};

export default BookingsPage;
